import React from 'react';

const Tracker = ({id, venue, marketName, total, settledDate}) => {

  const options = {
       timeZone:"Europe/Berlin",
       hour:  "2-digit",
       minute: "2-digit",
      second: "2-digit",
      hour12: false
    };

  const d = new Date(settledDate);

const dtUK = d.toLocaleString('en-US', options);

    return(
        <tr>
            <td className="cell">{venue}</td><td className="cell">{marketName}</td><td className="cell">{dtUK}</td><td className={total>0 ? "cell green" : "cell red"}> &pound;{total.toFixed(2)} </td>
        </tr>
    )
}

export default Tracker;
import React, {useState, useEffect} from 'react';
import './App.css';
import Tracker from './Tracker';
import Bills from './Bills';
import Login from './Login';
import {
  ComposedChart,
  Line,
  BarChart,
  Bar,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from 'recharts';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


const App = () => {

  const API_URL = `http://localhost:8080/totaltracker/`;

  const API_URL2 = `http://localhost:8080/totaltracker/hourlycum`;

  const API_URL3 = `http://localhost:8080/totaltracker/layamount`;

  const API_URL4 = `http://localhost:8080/totaltracker/litelist`;

  const [tracker, setTracker] = useState([]);

  const [litelist, setLitelist] = useState([]);

  const [cumulate, setCumulate] = useState([]);

  const [lay, setLay] = useState([]);

  var [numItems, setNumItems] = useState([10]);

  var [initialLoad, setInitialLoad] = useState(true);

 const loadData = async () => {
    const response = await fetch(API_URL);
    const data = await response.json();
    setTracker(data);
    const response2 = await fetch(API_URL2);
    const data2 = await response2.json();
    setCumulate(data2);
    const response3 = await fetch(API_URL3);
    const data3 = await response3.json();
    setLay(data3);
    const response4 = await fetch(API_URL4);
    const data4 = await response4.json();
    setLitelist(data4);
    setInitialLoad(false);
  }

  useEffect(() => {
	if (initialLoad)
        {
           loadData();
        }
	const interval = setInterval(() => loadData(), 30000)
        return () => {
          clearInterval(interval);
        }
    })

  function clickHandler(e) {
    if (e.currentTarget.id === "more")
    {
       setNumItems(++numItems);
    }
    else
    {
       setNumItems(--numItems);
    }
  }

  return (


<div className="App">
 <div className="grid-container">
   <div className="menu-icon">
    <i className="fas fa-bars header__menu"></i>
  </div>
<Router>
  <header className="header">
    <Login />
  </header>

  <aside className="sidenav">
    <div className="sidenav__close-icon">
      <i className="fas fa-times sidenav__brand-close"></i>
    </div>
    <ul className="sidenav__list">
      <Link to="/"><li className="sidenav__list-item">Dashboard</li></Link>
      <Link to="/bills"><li className="sidenav__list-item">Bills Management</li></Link>
      <li className="sidenav__list-item">Notifications</li>
      <li className="sidenav__list-item">Admin Settings</li>
      <li className="sidenav__list-item">Manage My Account</li>
    </ul>
  </aside>

  <main className="main">


    <div className="main-overview">
      <div className={!(lay.dailyStreak === undefined) && lay.dailyStreak > 0 ? "overviewcard_green" : "overviewcard"}>
        <div className="overviewcard__icon">Day Streak</div>
        <div className="overviewcard__info">{lay.dailyStreak}</div>
      </div>
      <div className={!(lay.totalSoFar === undefined) && lay.totalSoFar > 0 ? "overviewcard_green" : "overviewcard_red"}>
        <div className={!(lay.totalSoFar === undefined) && lay.totalSoFar > 0 ? "overviewcard__icon_green" : "overviewcard__icon_red"}>Current Total</div>
        <div className={!(lay.totalSoFar === undefined) && lay.totalSoFar > 0 ? "overviewcard__info_green" : "overviewcard__info_red"}>£{lay.totalSoFar === undefined ? "..." : lay.totalSoFar.toFixed(2)}</div>
      </div>
      <div className="overviewcard">
        <div className="overviewcard__icon">Bet Amount</div>
        <div className="overviewcard__info">£{lay.layAmount === undefined ? "..." : lay.layAmount.toFixed(2)}</div>
      </div>
      <div className="overviewcard">
        <div className="overviewcard__icon">Last Withdrawal</div>
        <div className="overviewcard__info">£TBC</div>
      </div>
    </div>

<Switch>
          <Route path="/bills">
            <Bills />
          </Route>
          <Route path="/">
    <div className="main-cards">
      <div className="card">
	<table className="fl-table">
            <thead><tr><th>Venue</th><th>Race Type</th><th>Settled Time</th><th>Profit / Loss</th></tr></thead>
            <tbody>
	{tracker === undefined || tracker.length === 0 ? (<tr><td>No Current Results</td></tr>) : tracker.slice((tracker.length - numItems < 0 ? 0 : tracker.length - numItems),tracker.length).map((t,id) => (
	  <Tracker key={id} id={t.id} marketName={t.marketName} venue={t.venue} total={t.total} settledDate={t.settledDate}/>
        ))}
	<tr><td><button id="less" onClick={clickHandler}>Show Less</button></td><td>&nbsp;</td><td>&nbsp;</td><td><button id="more" onClick={clickHandler}>Show More</button></td>
	</tr>
      </tbody>
      </table>
      </div>
      <div className="card">     {
        <BarChart
          width={500}
          height={300}
          data={litelist}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="settledDate" hide="true" />
          <YAxis />
          <ReferenceLine y={0} stroke="#000" />
          <Bar dataKey="total" fill="#8884d8" />
          </BarChart>
     }</div>
      <div className="card">
     {
        <ComposedChart width={500} height={300} data={cumulate}>
          <Line type="monotone" dataKey="Cumulative Total" stroke="#8884d8" strokeWidth={2} />
	  <Bar dataKey="Hourly Total" barSize={20} fill="#909090" />
	  <ReferenceLine y={0} stroke="black" />
	  <ReferenceLine y={25} stroke="green" />
	  <ReferenceLine y={50} stroke="green" />
	  <ReferenceLine y={75} stroke="green" />
          <ReferenceLine y={100} stroke="green" />
          <ReferenceLine y={-25} stroke="red" />
          <ReferenceLine y={-50} stroke="red" />
          <ReferenceLine y={-75} stroke="red" />
          <ReferenceLine y={-100} stroke="red" />
          <Tooltip />
        </ComposedChart>
     }</div>
    </div>
</Route>
</Switch>
</main>
</Router>

  <footer className="footer">
    <div className="footer__copyright">&copy; 2021 £etsthatpaymy£ills.com</div>
    <div className="footer__signature">Terms and Conditions</div>
  </footer>
</div>
    </div>

  )
};

export default App;

import React from 'react';
import Form from 'react-bootstrap/Form';
import Login from './Login';

class OTP extends React.Component {

  constructor(props) {
    super(props);
    this.state = [];
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    //this.props.onFormSubmit(this.state);
    const user = {
      username: this.props.username,
      password: this.state.otpcode
    }

    if (event.nativeEvent.submitter.name === 'otp_submit')
{
    fetch('http://localhost:8080/totaltracker/validate', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(
    user
  ),
}).then((resp)=>{   console.log(resp.status);
  return resp.text(); }).then((text)=>{
    this.state.username = user.username;
    this.state.password = undefined;
    if (text.length > 0)
    {
      this.state.sessionToken = text;
    }
  this.forceUpdate();
  console.log(text); });
  }

else
{
    fetch('http://localhost:8080/totaltracker/logout', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(
    user
  ),
}).then((resp)=>{   console.log(resp.status);
  return resp.text(); }).then((text)=>{
    if (text === 'true')
    {
      this.state.username = undefined;
      this.state.password = undefined;
      this.state.sessionToken = 'LOGOUT';
    }
  this.forceUpdate();
  console.log(text); });
  }

}
  render () {

    return(

        this.props.sessionToken === '2FA' && this.state.sessionToken === undefined && !(this.props.username === undefined) ?
        (<Form onSubmit={this.handleSubmit}>
        <table className="fl-table">
        		<tr><td>{this.props.username}</td><td><Form.Control id="otp" name="otpcode" type="password" placeholder="Enter OTP" onChange={this.handleChange}/></td><td><button name="otp_submit" type="submit">Submit Code</button></td></tr>
            </table>
        </Form>)
        : (this.state.sessionToken === 'LOGOUT' && this.state.username === undefined && this.state.password === undefined) ?
          <Login/>
            : (<Form onSubmit={this.handleSubmit}>
            <table className="fl-table">
          		<tr><td>{this.props.username}</td><td>{this.state.sessionToken}</td><td><button name="logout" type="submit">Log Out</button></td></tr>
              </table></Form>)


)};

}

export default OTP;

import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Bills extends React.Component {

  constructor(props) {
    super(props);
    this.initialState = {
      productName: '',
      price: '',
      sku: ''
    }
    this.state = this.initialState;

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;

    this.setState({
      [name]: value
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.onFormSubmit(this.state);
    this.setState(this.initialState);
  }

  render () {

    return(<div className="main-cards">
      <div className="card">
	<table className="fl-table">
            <thead><tr><th>Bill Description</th><th>Bill Frquency</th><th>Cost</th><th>Annualised Cost</th></tr></thead>
            <tbody>
      </tbody>
      </table>
      </div>
      <div className="card">
      <div>
        <h2>Add Product</h2>
        <Row>
          <Col sm={6}>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group controlId="productName">
                <Form.Label>Product Name</Form.Label>
                <Form.Control
                  type="text"
                  name="productName"
                  value={this.state.productName}
                  onChange={this.handleChange}
                  placeholder="Product Name"/>
              </Form.Group>
              <Form.Group controlId="sku">
                <Form.Label>SKU</Form.Label>
                <Form.Control
                  type="text"
                  name="sku"
                  value={this.state.sku}
                  onChange={this.handleChange}
                  placeholder="SKU" />
              </Form.Group>
              <Form.Group controlId="price">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="text"
                  name="price"
                  value={this.state.price}
                  onChange={this.handleChange}
                  placeholder="Price" />
              </Form.Group>
              <Form.Group>
                <Button variant="success" type="submit">Save</Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </div>
      </div>
      <div className="card">
<Form>
<table className="fl-table">
            <tbody>
		<tr><td><Form.Label>Bill Description</Form.Label></td><td><Form.Control type="text" placeholder="Enter Description" /></td></tr>
<tr><td><Form.Label>Bill Description</Form.Label></td><td><Form.Control type="text" placeholder="Enter Description" /></td></tr>
<tr><td><Form.Label>Bill Description</Form.Label></td><td><Form.Control type="text" placeholder="Enter Description" /></td></tr>
<tr><td><Form.Label>Bill Description</Form.Label></td><td>Test</td></tr>
      </tbody>
      </table>

</Form>
     </div>
    </div>

)};

}

export default Bills;

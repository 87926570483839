import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OTP from './OTP';

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = [{loginFail: false}];
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const name = event.target.name;
    const value = event.target.value;
    this.state.loginFail = false;

    this.setState({
      [name]: value
    })
  }

  handleSubmit(event) {
    event.preventDefault();
    //this.props.onFormSubmit(this.state);
    const user = {
      username: this.state.username,
      password: this.state.password
    }
    fetch('http://localhost:8080/totaltracker/login', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  body: JSON.stringify(
    user
  ),
}).then((resp)=>{   console.log(resp.status);
  return resp.text(); }).then((text)=>{
  if (text === '2FA')
  {
    this.state.username = user.username;
    this.state.sessionToken = text;
    this.state.password = undefined;
  }
  else {
    this.state.username = user.username;
    this.state.password = undefined;
    if (text.length > 0)
    {
      this.state.sessionToken = text;
    }
    else {
      this.state.loginFail = true;
    }
  }
  this.forceUpdate();
  console.log(text); });
  }

  render () {

    return(

        this.state.sessionToken === undefined ?
            (<Form onSubmit={this.handleSubmit}><table className="fl-table">
        		<tr><td><Form.Label>Username</Form.Label></td><td><Form.Control name="username" type="text" placeholder="Enter Username" onChange={this.handleChange} /></td><td><Form.Label>Password</Form.Label></td><td><Form.Control name="password" type="password" placeholder="Enter Password" onChange={this.handleChange}/></td><td><button type="submit">Login</button></td>{this.state.loginFail ? (<td>Login Failed Please Try Again</td>) : ""}</tr>
            </table></Form>)
        :   (<OTP username={this.state.username} sessionToken={this.state.sessionToken}/>)



)};

}

export default Login;
